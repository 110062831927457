<template>
    <div class="testBlockSamplePop">
        <div class="nav-list">
            <div
                v-for="(item,index) in navList"
                :key="index"
                :class="{'nav-item':true,active:index==activeNav}"
                @click="handleActiveNav(index)"
            >
                {{ item.name }}
            </div>
        </div>
        <div class="content-wrapper">
            <component :is="navList[activeNav].component"></component>
        </div>
    </div>
</template>

<script>
export default {
    name: 'test-block-sample-pop',
    data() {
        return {
            navList: [
                { name: '编码规则设置', component: () => import('./codeRuleSet/index') },
                { name: '试块留样设置', component: () => import('./testBlockSampleSet/index') },
            ],
            activeNav: 0,
        };
    },
    methods: {
        handleActiveNav(index) {
            this.activeNav = index;
        },
    },
};
</script>

<style lang="stylus" scoped>
.testBlockSamplePop
    width 100%
    height 100%
    background #F8F8F8
    display flex
    .nav-list
        padding 0.19rem
        width: 1.8rem;
        height: 100%;
        background: #F8F8F8;
        border: 1px solid #F0F0F0;
        .nav-item
            display flex
            justify-content center
            align-items center
            width: 1.4rem;
            height: 0.44rem;
            background: #EEEEEE;
            border-radius: 0.04rem;
            border: 1px solid #CACACA;
            font-size 0.16rem
            color #555555
            margin-bottom 0.18rem
            cursor pointer
            &.active
                background: #E6F7FF;
                border-radius: 0.04rem;
                border: 1px solid #2898FF;
                border-left 0.04rem solid #2898FF;
                color #1890FF
    .content-wrapper
        width calc(100% - 1.8rem)
        height 100%;
        background #fff
</style>
